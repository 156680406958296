body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
    height: 600px;
    width: 100%;
    z-index: 1;
}

@media only screen and (min-width: 993px) {
    .padded-left {
        padding-left: 30px
    }
}

@media only screen and (max-width: 993px) {
    .padded-right {
        padding-right: 30px !important;
    }
}

body {
    background-image: url("images/background/background.jpg");
    background-size: cover;
    background-color: #eeeeee;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}


p.bold {
    font-weight: bold;
}

.darkblue {
    background-color: #100056 !important;
}

.logo-right {
    display: block;
    line-height: 22pt !important;
}

.logo-left {
    font-size: 17pt !important;
    position: absolute;
    left: 20px;
    top: -22px;
    line-height: 30px !important;
}

.exhibition-card {
    background: linear-gradient(to bottom, #100056 130px, #ffffff 130px);
}

.leaflet-popup-content-wrapper {
    background: linear-gradient(0deg, #ffffff 71%, #100056 29%);
}

p.marker-title {
    position: relative;
    top: -7px;
    color: white;
    font-weight: bold;
    line-height: 18px !important;
}

p.artist-name {
    font-size: 20pt;
    color: #039be5;
}

main {
    flex: 1 0 auto;
}

.footer-logo {
    padding-right: 10px;
}

.footer-container {
    padding-left: 10px;
    overflow: visible !important;
}

table {
    table-layout: fixed;
    width: 100%;
}

td {
    width: 25%;
    vertical-align: top !important;
}

.footer-text {
    font-size: 7pt;
    color: white;
    position: relative;
    top: 5px;
}

.page-footer {
    bottom: -10px !important;
}

td.vertical-bottom-error {
    vertical-align: middle !important;
    padding-top: 25px;
    padding-right: 150px;
}

td.exhibtion-entry {
    width: 100px;
}

p.arabic-text {
    direction: rtl;
    text-align: right;
}

a.as-text {
    color: black;
    text-decoration: none;
}

p.cursive {
    font-style: italic;
}

p.small {
    font-size: 10pt;
}
